import React, { useState, useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import * as projectImages from "../../assets/index";
import { colors } from "../../assets/colors/index";
import InputField from "../../components/InputField";
import { useForm } from "react-hook-form";
import AdmeetService from "../../api/admeet/AdmeetService";
import IndustriesService from "../../api/industries/IndustriesService";
import gettingCategories from "../../api/category/CategoryService";
import SelectField from "../../components/SelectField";
import { Helmet } from "react-helmet";
import { ErrorToaster, SuccessToaster } from "../../components/toaster/Toaster";
import BlogServices from "../../api/blog/BlogService";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BasicModal from "../../components/modal/Modal";

const Admeet = () => {
  const [title, setTitle] = useState("ADMEET - ADSTREET");
  const [token, setToken] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [service, setService] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [adbazarService, setAdbazarService] = useState([]);

  const navigate = useNavigate();

  const location = useLocation();
  const item = location?.state;
  console.log("Receiving item=>", item?._id);

  // let browserToken = localStorage.getItem("user");
  // console.log("token", token);

  const getAllIndustries = async () => {
    try {
      const result = await gettingCategories.getCategory(1, 100);
      // console.log("Category", result);
      if (result.responseCode == 200) {
        setService(result?.data?.categories);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const submitInterest = async (id) => {
  //   // alert(id);
  //   try {
  //     const result = await BlogServices.postInterest(id);
  //     alert(result);
  //     return result;
  //   } catch (e) {

  //     console.log(e);
  //   }
  // };

  const submitForm = async (formData) => {
    console.log("Receiving data", formData);
    const obj = {
      name: formData.fullName,
      email: formData.email,
      phoneNumber: formData.phone,
      service: selectedService?.name,
      companyName: formData.company,
      blogId: item?._id,
      expressedInterest: true,
    };

    try {
      // const token = localStorage.getItem("token");
      // alert(token);
      // if (token) {
      const result = await AdmeetService.register(obj);
      if (result.responseCode === 200) {
        SuccessToaster("Form submitted successfully");
        reset();
        setSelectedService(null);
        // BlogServices.postInterest(item?._id);
        // alert("Received token", token);

        console.log("Through token", token);
      }
      // } else {
      //   navigate("/login");
      // }
    } catch (e) {
      if (e.response.data.responseCode == 400) {
        ErrorToaster(e.response.data.message);
      }
    }
  };

  useEffect(() => {
    getAllIndustries();
  }, []);

  return (
    <>
      <Helmet>
        <title>{title ? title : "No title"}</title>
      </Helmet>
      <Box
        component={"main"}
        sx={{
          backgroundImage: `url(${projectImages.images.adMeetBgImg})`,
          width: "100%",
          height: "auto",
          backgroundPosition: "right",
          backgroundSize: "cover",
        }}
      >
        <Box
          component={"section"}
          sx={{
            p: 2,
          }}
        >
          <Grid container>
            {/* Admeet logo */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item mt={{ xs: 5, sm: 5, md: 7 }}>
                  <Box component={"img"} src={projectImages.images.adMeetLogo} alt="Admeet text image" />
                </Grid>
              </Grid>
            </Grid>
            {/* Adstreet present */}
            {/* <Grid item xs={12}>
              <Grid container>
                <Grid item>
                  <Box
                    component={"h5"}
                    sx={{
                      color: colors.adMeetTextColor,
                      fontWeight: "normal",
                      fontSize: { lg: "24px" },
                      margin: "8px 0 0 0",
                    }}
                  >
                    ADSTREET PRESENTS
                  </Box>
                </Grid>
              </Grid>
            </Grid> */}

            {/* An exclusive */}
            {/* <Grid item xs={12}>
              <Grid container>
                <Grid item>
                  <Box
                    component={"h5"}
                    sx={{
                      color: colors.adMeetTextColor,
                      fontWeight: "normal",
                      fontSize: { lg: "32px" },
                      margin: "12px 0 0 0",
                    }}
                  >
                    AN EXCLUSIVE ADMEET FOR MARKETERS
                  </Box>
                </Grid>
              </Grid>
            </Grid> */}
            {/* Great debate */}
            {/* <Grid item xs={12}>
              <Grid container>
                <Grid item>
                  <Box
                    component={"h5"}
                    sx={{
                      color: colors.primaryColor,
                      fontWeight: "medium",
                      fontSize: { lg: "29px" },
                      margin: "12px 0 0 0",
                    }}
                  >
                    The Great Debate 2.0 - Clients vs Agencies
                  </Box>
                </Grid>
              </Grid>
            </Grid> */}
            {/* February 2024 */}
            {/* <Grid item xs={12}>
              <Grid container>
                <Grid item>
                  <Box
                    component={"p"}
                    sx={{
                      color: colors.adMeetTextColor,
                      fontSize: "18px",
                      fontWeight: "bold",
                      m: "13px 0 0 0",
                    }}
                  >
                    28th February 2024 - 07:00 PM to 09:00 PM at Habitt City, Tipu Sultan Road
                  </Box>
                </Grid>
              </Grid>
            </Grid> */}
            {/* Register today */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item>
                  <Box
                    component={"p"}
                    sx={{
                      color: colors.adMeetTextColor,
                      fontSize: "28px",
                      fontWeight: "medium",
                      m: "15px 0 0 0",
                    }}
                  >
                    Register today to fix your spot at the event!
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box component={"form"} onSubmit={handleSubmit(submitForm)}>
            <Grid container mt={2} rowSpacing={2} columnSpacing={1}>
              {/* Your full name and phone no. input start */}

              <Grid item xs={12} sm={6} md={12}>
                <Grid container>
                  {/* full name */}
                  <Grid item xs={12} sm={12} md={6} ml={4}>
                    <InputField
                      placeholder={"Your Full Name (Required)"}
                      register={register("fullName", {
                        required: "Your Full Name",
                      })}
                      error={errors?.fullName && true}
                      helperText={errors?.fullName?.message}
                      admeet={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* phone number */}
              <Grid item xs={12} sm={6} md={12}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} ml={4}>
                    <InputField
                      placeholder={"Your Phone Number (Required)"}
                      register={register("phone", {
                        required: "Your Phone Number",
                      })}
                      error={errors?.phone && true}
                      helperText={errors?.phone?.message}
                      admeet={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* Your full name and phone no. input end */}

              {/* Email address and company name input start */}

              <Grid item xs={12} sm={6} md={12}>
                <Grid container>
                  {/* email address */}
                  <Grid item xs={12} sm={12} md={6} ml={4}>
                    {/* <Grid item xs={12} sm={12} md={12}> */}
                    <InputField
                      placeholder={"Your Email Address (Required)"}
                      register={register("email", {
                        required: "Your Email Address",
                      })}
                      error={errors?.email && true}
                      helperText={errors?.email?.message}
                      admeet={true}
                    />
                    {/* </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
              {/* company name */}

              <Grid item xs={12} sm={6} md={12}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} ml={4}>
                    <InputField
                      placeholder={"Your Company Name (Required)"}
                      register={register("company", {
                        required: "Your Company Name",
                      })}
                      error={errors?.company && true}
                      helperText={errors?.company?.message}
                      admeet={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Email and company name input end */}

              {/* Select your category start */}
              {/* <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={12} sm={6} md={6}>
                    {service?.map((item, i) => console.log(item))}
                    <SelectField
                      required
                      selectType={"category"}
                      data={service?.filter((item) => item?.type === "adbazaar")}
                      value={selectedService !== null ? selectedService?._id : ""}
                      setValue={setSelectedService}
                    />
                  </Grid>
                </Grid>
              </Grid> */}
              {/* Select your category end */}
              {/* Signup button*/}
              <Grid item xs={12} sm={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      // onClick={() => submitInterest(item?._id)}
                      type={"submit"}
                      sx={{
                        ml: 4,
                        color: colors.white,
                        backgroundColor: colors.primaryColor,
                        width: { xs: "100%" },
                        mb: 5,
                        "&:hover": {
                          backgroundColor: colors.primaryColor,
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Admeet;
