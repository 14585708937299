export const data = [
  {
    title: "Last Updated: 1st August 2023",
    paragraph:
      "Welcome to the ADSTREET app (“App”). These Terms of Use outline the terms and conditions that govern your access to and use of the App. By accessing or using the App, you agree to be bound by these Terms of Use. If you do not agree with these terms, please refrain from using the App.",
    style: {
      mTitle: "50px",
      mPara: "50px",
    },
  },

  // Acceptance of terms

  {
    title: "1. Acceptance of Terms:",
    paragraph: "By accessing or using the App, you acknowledge that you have read, understood, and agree to abide by these Terms of Use and any future amendments.",
    style: {
      mTitle: "40px",
      mPara: "10px",
    },
  },

  // User accounts

  {
    title: "2. User Accounts:",
    paragraph:
      "To access certain features of the App, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and are liable for all activities associated with your account.",

    style: {
      mTitle: "40px",
      mPara: "10px",
    },
  },

  // User conduct

  {
    title: "3. User Conduct:",
    text: "You agree not to:",
    listOne: "Use the App for any unlawful, harmful, or fraudulent activities.",
    listTwo: "Transmit any content that is offensive, inappropriate, or violates third-party rights.",
    listThree: "Attempt to gain unauthorized access to the App’s systems, accounts, or data.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Contect submission

  {
    title: "4. Content Submission:",
    paragraph:
      "By submitting content to the App, you grant ADSTREET a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, distribute, and display the content for the purposes of operating and promoting the App.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Intellectual property

  {
    title: "5. Intellectual Property:",
    paragraph:
      "All content and materials on the App, including but not limited to text, images, logos, and software, are protected by intellectual property rights. You may not reproduce, distribute, or modify any content without our prior written consent.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Third-party links and services

  {
    title: "6. Third-Party Links and Services:",
    paragraph:
      "The App may contain links to third-party websites or services. ADSTREET does not endorse or assume responsibility for the content or practices of these third-party sites. Your interactions with third-party sites are at your own risk.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Disclaimers and limitation of liability

  {
    title: "7. Disclaimers and Limitation of Liability:",
    paragraph:
      "The App is provided “as is” and “as available.” ADSTREET makes no warranties or representations, express or implied, regarding the App’s accuracy, reliability, or suitability for a particular purpose.",
    paragraphTwo: "ADSTREET is not liable for any damages, losses, or liabilities arising from your use of the App or any content contained therein.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Indemnification

  {
    title: "8. Indemnification:",
    paragraph:
      "You agree to indemnify and hold harmless ADSTREET, its affiliates, partners, and employees from any claims, losses, damages, liabilities, and expenses arising from your use of the App or any violation of these Terms of Use.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Termination

  {
    title: "9. Termination:",
    paragraph: "ADSTREET reserves the right to suspend or terminate your access to the App at any time for any reason, without notice.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Changes to terms

  {
    title: "10. Changes to Terms:",
    paragraph:
      "ADSTREET may update these Terms of Use from time to time. You are responsible for reviewing the latest version of these terms, as continued use of the App constitutes acceptance of any modifications.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Governing law and dispute resolution

  {
    title: "11. Governing Law and Dispute Resolution:",
    paragraph:
      "These Terms of Use are governed by the laws of [Jurisdiction]. Any disputes arising from or related to these terms will be subject to the exclusive jurisdiction of the courts in [Jurisdiction].",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Refund Policy

  {
    title: "12. Refund Policy:",
    paragraph:
      "If you're not satisfied with our services, you're eligible for a refund. Refunds are subject to review and specific conditions with service provider check , including proper documentation of dissatisfaction and adherence to the terms of service. Please contact our support team within 30 days of purchase to initiate the refund process.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },

  // Contact us

  {
    title: "13. Contact Us:",
    paragraph: "If you have any questions, concerns, or inquiries regarding these Terms of Use, please contact us at admin@adstreet.com.pk",
    paragraphTwo: "Thank you for using the ADSTREET app. We hope you enjoy your experience and adhere to these terms to ensure a safe and enjoyable community for all users.",
    style: {
      mTitle: "50px",
      mPara: "10px",
    },
  },
];
