import React, { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import * as projectImages from "../../assets/index";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Button } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Typography from "@mui/material/Typography";
import { colors } from "../../assets/colors/index";
import AdleaksService from "../../api/adleaks/AdleaksService";
import moment from "moment";
import { baseUrl } from "../../axios";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Banner from "../../components/banner/Banner";
import "./admeet.css";
import gettingBanner from "../../api/banner/BannerService";
import gettingCategories from "../../api/category/CategoryService";

const Admeet = () => {
  const [admeet, setAdmeet] = useState([]);
  const [title, setTitle] = useState("ADMEET - ADSTREET");
  const [banner, setBanner] = useState([]);
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();
  const baseUrl = "https://adstreet.mangotech-api.com";

  console.log("Admeet", admeet);

  const getAllAdmeet = async () => {
    try {
      const result = await AdleaksService.getAllAdleaks("", "", "admeet");
      if (result.responseCode == 200) {
        setAdmeet(result?.data?.blogs);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAllCategories = async () => {
    try {
      const result = await gettingCategories.getCategory("", "", "admeet");
      if (result.responseCode == 200) {
        const filtered = result?.data?.categories.filter((item) => item.name != "Plan Event")
        setCategories(filtered);
      }
    } catch (err) {
      console.log(err)
    }
  }

  const returnToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const bannerImg = [
  //   {
  //     img: "https://adstreet.com.pk/wp-content/uploads/2024/04/Web-Banner-2-scaled.jpg",
  //   },
  //   {
  //     img: "https://adstreet.com.pk/wp-content/uploads/2024/04/Web-Banner-2-scaled.jpg",
  //   },
  //   {
  //     img: "https://adstreet.com.pk/wp-content/uploads/2024/04/Web-Banner-2-scaled.jpg",
  //   },
  // ];

  // Function to extract and render text content within <p> tags
  const renderOnlyParagraphs = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Select all paragraphs within the tempDiv
    const paragraphs = tempDiv.querySelectorAll("p");

    let paragraphText = "";
    // Concatenate the text content of each paragraph
    paragraphs.forEach((paragraph) => {
      paragraphText += paragraph.textContent + " "; // Concatenating text content with a space
    });

    return paragraphText;
  };

  const handleBanner = async () => {
    try {
      let result = await gettingBanner.getBanner();
      if (result.responseCode === 200) {
        let images = result?.data.banners.filter((item, index) => {
          return item?.type === "admeet";
        });
        setBanner(images);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllAdmeet();
    handleBanner();
    getAllCategories();
  }, []);
  return (
    <>
      <Helmet>
        <title>{title ? title : "No title"}</title>
      </Helmet>

      <Box component={"main"}>
        {/* Adleaks bg section */}
        <Box
          component={"section"}
          sx={{
            backgroundImage: `url(${projectImages.images.adMeetBgImg})`,
            width: "100%",
            height: "auto",
            backgroundPosition: "center",
            backgroundSize: "cover",
            pt: 2,
            pb: 4,
          }}
        >
          <Box
            component={"img"}
            src={projectImages.images.adMeetLogo}
            sx={{
              display: "block ",
              margin: "0 auto",
              mt: { xs: 4, md: 4 },
            }}
          />
          <Box
            component={"h2"}
            sx={{
              color: colors.white,
              textAlign: "center",
              fontSize: { xs: "15px", md: "18.72px" },
              mt: { xs: 3.5, sm: 3, md: 2 },
              ml: "auto",
              mr: "auto",
              width: "90%",
            }}
          >
            Stay ahead of the curve with ADMEET' industry news updates!
          </Box>
        </Box>
        <Container>
          <Grid
            container
            // rowGap={2}
            spacing={2}
            sx={{
              mb: "30px",
            }}
          >
            <Grid item md={12} sm={12} xs={12}>
              <Box sx={{ pt: 2 }}>
                <Banner baseUrl={baseUrl} images={banner} key={"admeet"} />
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box sx={{ display: "flex", gap: 2 }}>
                {categories.map((item, ind) => (
                  <Button
                    key={ind}
                    variant={"outlined"}
                    sx={{
                      borderColor: colors.primaryColor,
                      color: colors.primaryColor,
                      ":hover": {
                        borderColor: colors.blackShade,
                        color: colors.blackShade
                      }
                    }}
                  >
                    {item.name}
                  </Button>
                ))}
              </Box>
            </Grid>
            {admeet.map(
              (item, index) =>
                item?.type === "admeet" && (
                  <Grid key={index} item xs={12} sm={6} lg={4}>
                    <Card
                      sx={{
                        maxWidth: { xs: 330, sm: 280, md: 400 },
                        margin: "0 auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        borderRadius: 0,
                        boxShadow: "none",
                      }}
                    >
                      <Box
                        component={"img"}
                        onClick={() => navigate(`/admeet-blog/${item?._id}`, { state: item })}
                        sx={{ height: 200, width: "100%", objectFit: "cover", cursor: "pointer" }}
                        src={baseUrl + item?.image}
                        alt="Card image"
                      />
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          p: 0,
                          // pt: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%"
                          }}
                        >
                          <Box
                            sx={{
                              textDecoration: "none",
                              color: colors.cardTitleColor,
                              height: "auto",
                              backgroundColor: "transparent",
                              border: "none",
                              textAlign: "left",
                              cursor: "pointer",
                              display: "flex",
                              p: 0,
                              mt: { xs: 1, md: 2 },
                            }}
                            component={"button"}
                            onClick={() => navigate(`/admeet-blog/${item?._id}`, { state: item })}
                            gutterBottom
                            variant="h6"
                            fontWeight={"bold"}
                            fontSize={"18px"}
                          >
                            {item?.title}
                          </Box>
                          <Typography marginTop={"5px"} gutterBottom component="small" fontSize={"12px"} display={"block"} color={colors.cardsDataColor}>
                            {moment(item?.date).format("LL")}
                          </Typography>
                        </Box>
                        <Typography sx={{ m: 0 }} component={"p"} color={colors.blogCardsTextColor} fontSize={"14px"}>
                          {/* {parse(item?.content.slice(0, 150))} */}
                          {renderOnlyParagraphs(item?.content.slice(0, 175))}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{
                          p: 0,
                        }}
                      >
                        <Box
                          component={"a"}
                          sx={{
                            fontSize: "small",
                            textDecoration: "none",
                            color: colors.textColor,
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            cursor: "pointer",
                            p: 0,
                            mt: 0.2,
                          }}
                        >
                          <Button onClick={() => navigate(`/admeet-blog/${item?._id}`, { state: item }, returnToTop())} sx={{ p: 0, color: colors.textColor, fontSize: "12px", fontWeight: "bold" }}>
                            Read more
                          </Button>
                          <KeyboardDoubleArrowRightIcon sx={{ fontSize: "small", position: "relative", top: "3px", p: 0 }} />
                        </Box>
                      </CardActions>
                    </Card>
                  </Grid>
                )
            )}
          </Grid>
        </Container>
      </Box >
    </>
  );
};

export default Admeet;
